import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html, body {
    background: #fff;
    overflow-x: hidden !important;
    font-family:  Helvetica, Tahoma, Arial, sans-serif, 'PingFang SC';
    min-height: calc(1300px);
    position: relative;

    .grecaptcha-badge { visibility: hidden; }
}
`;
