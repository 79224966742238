import { memo, useEffect } from "react";
import Alert from "react-bootstrap/Alert";

type Props = {
  variant:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  text: string;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showAlert: boolean;
};

const AlertComponent: React.FC<Props> = ({
  variant,
  text,
  setShowAlert,
  showAlert,
}) => {
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 8000);
    }
    //eslint-disable-next-line
  }, [showAlert]);

  return (
    <Alert
      variant={variant}
      style={{
        position: "absolute",
        left: "50%",
        bottom: 0,
        transform: "translateX(-50%)",
        zIndex: 5
      }}
      show={showAlert}
    >
      {text}
    </Alert>
  );
};

export default memo(AlertComponent);
