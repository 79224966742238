import { useEffect } from "react";
import Homepage from "./pages/Homepage";

// We want to show our site only on casaè.it site
const checkIfInIframe = () => {
  if (window.location !== window.parent.location) {
    // The page is in an iFrames
    console.log("The page is in an iFrame");
  } else {
    // The page is not in an iFrame
    console.log("The page is not in an iFrame");
  }
};

function App() {
  useEffect(() => {
    checkIfInIframe();
  }, []);

  return <Homepage />;
}

export default App;
