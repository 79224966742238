import styled from "styled-components";

export const Container = styled.div`
  width: calc(100% - 40px);
  max-width: 1200px;
  padding: 50px 20px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 15px 2px rgb(0 0 0 / 20%);
  color: #fff;
  flex-flow: column nowrap;
  background: #fff;
  verflow-x: hidden !important;
  margin: -90px 0 20px;

  #incarico {
    width: calc(800px - 40px);
    height: 100%;
    margin-top: 10px;
    background: url("pdf-service.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: 80px;
    flex-direction: column;
  }

  .ragione {
    width: 150px;
    font-size: 12px;
    color: #333;
    font-weight: bold;
    text-align: right;
    margin: 5px 0;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 20px 0 0;

    p {
      font-size: 14px;
      font-style: italic;
      margin-left: 10px;
      width: max-content;
    }

    svg {
      font-size: 25px;
      color: rgb(205, 170, 91);
    }
  }

  #copy-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.85);
    width: calc(800px - 40px) !important;
  }

  #footer-print {
    font-size: 50px;
  }

  #map {
    width: 100%;
  }

  h5 {
    width: 100%;
    margin: 0 0 5px;
  }

  .center {
    justify-content: center;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000;

    :hover {
      opacity: 0.6;
    }
  }

  .no-margin {
    margin-bottom: 0;
  }

  #back-icon {
    font-size: 25px;
    color: #000;
  }

  .print {
    background: rgb(205, 170, 91);
    padding: 6px 25px;
    color: #fff;
  }

  #submit {
    background: rgb(205, 170, 91);
    width: 100%;
    margin-top: 50px;
    max-width: 400px;
    color: #fff;
    opacity: 0.8;
    text-align: center;
    padding: 8px 0;
    cursor: pointer;

    :hover {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    overflow: hidden !important;
    overflow-x: hidden !important;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  color: #333;
`;

export const PrintDiv = styled.div`
  width: 826px;
  height: 1169px;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: rgba(80, 79, 84, 1);

  h6,
  p {
    margin: 0;
  }

  #slogan-print {
    font-size: 20px;
  }

  .font-size,
  h5 {
    font-size: 17px;
  }

  #header {
    margin-top: -140px;
  }

  #slogan {
    color: #fff;
    user-select: none;
  }

  #casa-logo-print {
    width: 400px;
    margin-top: -150px;
  }

  .black {
    color: rgba(80, 79, 84, 1);
  }

  .container-print {
    margin: 25px 0 10px;
    width: 100%;
    max-width: calc(800px - 40px);
  }

  #divider-line {
    display: flex;
    align-items: center;
    flex-align: stretch;
    background: rgb(205, 170, 91);
    width: 2px;
  }

  #container-data {
    width: calc(800px - 40px);
    border: 1px solid rgba(80, 79, 84, 1);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 20px 20px 0 20px;
    height: 800px;
  }

  .break {
    display: flex;
    flex-basis: 100%;
  }

  #map {
    width: 100%;
    margin-bottom: 10px;
  }

  .leaflet-container {
    width: 100%;
    height: 250px;
    margin-top: 10px;
  }

  #value {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    h5 {
      font-size: 28px;
    }

    h5:last-of-type {
      text-align: right;
    }
  }
`;

export const Hidden = styled.div`
  position: absolute;
  left: 500%;
  top: 0;
  z-index: 10;
`;
