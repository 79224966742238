import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  z-index: 4;

  .text-ready {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }

  .name-text {
    text-align: left;
    width: calc(100% - 160px);
    padding: 0 80px;
  }

  #service-slogan {
    font-size: 20px;
    color: #333;
    margin-top: 10px;
  }

  #name-form {
    width: 100%;
    max-width: 440px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    .width {
      width: 100%;
    }

    #checkbox-label {
      margin: 0 0 0 20px;
      width: 100%;
    }

    #checkbox,
    #checkbox-2 {
      transform: scale(1.5);
    }

    #break {
      flex-basis: 100%;
    }

    #checkbox-div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 10px;

      p {
        margin: 0 0 0 20px;
        font-weight: bold;
      }

      input {
        margin: 0;
        width: min-content;
      }
    }

    input,
    label {
      width: 99%;
    }

    input {
      margin-bottom: 20px;
    }

    .input-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .width: {
      width: 100%;
    }

    #submit {
      background: rgb(205, 170, 91);
      width: 100%;
      margin-top: 50px;
      max-width: 450px;
      color: #fff;
      opacity: 0.8;
      text-align: center;
      cursor: pointer;

      :hover {
        opacity: 1;
      }
    }
  }

  #casa-logo {
    width: 300px;
    margin: 30px 0 0;
  }

  #form-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  #slogan {
    color: #fff;
    user-select: none;
  }

  .copyright {
    color: #333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 1px;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    margin: 0 0 20px;

    a {
      color: #333;
      text-decoration: none;
    }

    a:visited {
      color: #333;
    }

    a:hover {
      color: rgb(205, 170, 91);
    }
  }

  #incarico {
    width: calc(800px - 40px);
    height: 100%;
    margin-top: 10px;
    background: url("banner-valutazioni.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 80px;
    flex-direction: column;
  }

  .incarico-text {
    width: 718px;
    margin-top: 10px;
    color: rgb(87, 87, 86);
    line-height: 18px;
    font-size: 17px;
  }

  .ragione {
    width: 150px;
    font-size: 12px;
    color: #333;
    font-weight: bold;
    text-align: right;
    margin: 5px 0;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 20px 0 0;

    p {
      font-size: 14px;
      font-style: italic;
      margin-left: 10px;
      width: max-content;
    }

    svg {
      font-size: 25px;
      color: rgb(205, 170, 91);
    }
  }

  #copy-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.85);
    width: calc(800px - 40px) !important;
    border-top: 1px solid rgb(87, 87, 86);
    margin-top: 5px;
  }

  #footer-print {
    font-size: 50px;
  }

  @media (max-width: 768px) {
    #slogan {
      text-align: center;
      font-size: 13px;
      padding: 0 10px;
    }

    #casa-logo {
      width: 230px;
    }

    .name-text {
      width: 100%;
      min-width: 100%;
      padding: 0;
    }

    .text-ready {
      font-size: 20px;
    }

    #checkbox-label {
      font-size: 15px;
    }

    #service-slogan {
      text-align: center;
      font-size: 28px;
      margin: 0 0 10px;
    }
  }
`;

export const Form = styled.form`
  width: calc(100% - 40px);
  max-width: 1200px;
  padding: 20px 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 15px 2px rgb(0 0 0 / 20%);
  color: rgba(80, 79, 84, 1);
  flex-flow: column nowrap;
  background: #fff;
  margin: -150px 0 20px;
  background: #f4f4f4;
  position: relative;

  #terms-form {
    font-size: 11px;
    margin: 0;
    width: 100%;
    color: #333;
    letter-spacing: 0;
    text-align: left;

    a {
      color: #333;

      :hover {
        color: rgb(205, 170, 91);
      }
    }
  }

  label {
    font-weight: bold;
    text-align: left;
    font-size: 18px;
    margin-top: 10px;
    user-select: none;
  }

  .input-subtitle {
    font-size: 13px;
    padding: 0;
    margin: 0;
    color: #aaaaad;
    user-select: none;
  }

  input {
    padding: 6px 12px;
    width: 100%;
    border: 1px solid #c3c3c5;
  }

  .box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .left {
    margin-right: 25px;
  }

  .small-input {
    width: 70px;
  }

  .flex-end {
    align-items: flex-end;
  }

  #town,
  #street {
    min-width: 300px;
  }

  #number,
  #zip {
    max-width: 100px;
  }

  #submit {
    background: rgb(205, 170, 91);
    width: 100%;
    margin: 50px 0 20px;
    max-width: 400px;
    color: #fff;
    opacity: 0.8;
    text-align: center;
    padding: 8px 0;
    cursor: pointer;

    :hover {
      opacity: 1;
    }
  }

  .required-color {
    color: red;
  }

  .no-grow {
    flex-grow: 0;
  }

  .center-row {
    justify-content: center;
  }

  .custom-dropdown {
    border-radius: 0;
    background: none;
    border: 1px solid #c3c3c5;
    color: #000;
    background: #fff;
  }

  #category {
    border-radius: 0;
    background: rgb(205, 170, 91);
    border: 1px solid #c3c3c5;
    font-size: 23px;
    letter-spacing: 2px;
    padding: 8px 30px;
  }

  #construction {
    min-width: 300px;
    border-radius: 0;
    background: none;
    border: 1px solid #c3c3c5;
    margin-right: 30px;
    color: #000;
    background: #fff;
  }

  #terms {
    width: 100%;
  }

  #description-service {
    background: ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8px 20px;
    color: #333;
    width: 100%;
    margin-bottom: 30px;

    p {
      margin: 0 0 10px;
      font-size: 18px;
      width: 80%;
      text-align: left;
    }

    h5 {
      font-weight: bold;
      font-size: 35px !important;
      color: #504f56;
    }

    svg {
      font-size: 110px;
      margin-bottom: 10px;
      color: rgba(205, 170, 91, 0.85);
    }
  }

  @media (max-width: 768px) {
    #category {
      padding: 5px 8px;
      font-size: 14px;
      word-break: break-word;
      min-width: 220px;
      letter-spacing: 0;
    }

    #town,
    #street {
      width: 100%;
      max-width: 100%;
      min-width: 0;
    }

    #description-service {
      margin: 0;
      width: calc(100% - 40px);
      padding: 20px;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  min-height: 700px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-image: url("header-family.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -170px;

  img {
    width: 100%;
  }

  @media (max-width: 1240px) {
    background-position: center;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 2500px) {
    min-height: 900px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-bottom: 20px;
}
`;
