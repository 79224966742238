import { ClientData, FormData, ResponseData } from "../pages/Homepage";

const sendForm = async (
  form: FormData,
  house_id: number,
  recaptchaToken: string
) => {
  return await fetch(
    process.env.NODE_ENV === "production"
      ? "api/uploadForm"
      : "http://localhost:8080/api/uploadForm",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...form, house_id, recaptchaToken }),
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw Error("Qualcosa è andato storto, riprova");
      }
    })
    .then((data: ResponseData) => {
      return data;
    })
    .catch((err) => {
      console.log("sendForm", err);
      return null;
    });
};

const uploadClientInfo = async (
  form: FormData,
  client: ClientData,
  house_id: number,
  recaptchaToken: string
) => {
  return await fetch(
    process.env.NODE_ENV === "production"
      ? "api/getValutationWithClient"
      : "http://localhost:8080/api/getValutationWithClient",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        form: { ...form },
        client: { ...client, house_id },
        recaptchaToken,
      }),
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      throw Error;
    })
    .then((data: ResponseData) => {
      return data;
    })
    .catch((err) => {
      console.log("uploadClientInfo", err);
      return null;
    });
};

const uploadHouseInfo = async (form: FormData, recaptchaToken: string) => {
  return await fetch(
    process.env.NODE_ENV === "production"
      ? "api/newHouse"
      : "http://localhost:8080/api/newHouse",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...form, recaptchaToken }),
    }
  )
    .then((res) => {
      if (res.status === 201) {
        return res.json();
      }
      throw Error;
    })
    .then((data: { id: number }) => {
      return data.id;
    })
    .catch((err) => {
      console.log(err);
      return 0;
    });
};

const useApi = {
  sendForm,
  uploadClientInfo,
  uploadHouseInfo,
};

export default useApi;
