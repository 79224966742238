import "leaflet/dist/leaflet.css";
import { memo } from "react";
import { ClientData, FormData, ResponseData } from "../pages/Homepage";
import { Container, Row, PrintDiv } from "../theme/result";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

type Props = {
  setShowResult: React.Dispatch<React.SetStateAction<boolean>>;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  clientData: ClientData;
  setFormInfo: React.Dispatch<React.SetStateAction<FormData>>;
  accepted: boolean;
};

const ResultContainer: React.FC<Props> = ({
  setShowForm,
  clientData,
  setShowResult,
  setFormInfo,
  accepted,
}) => {
  return (
    <>
      <Container>
        <Row>
          {accepted ? (
            <p>
              Grazie per aver usufruito del nostro servizio, abbiamo inviato una
              mail all'indirizzo da te fornito: <b>{clientData.email}</b> con il
              risultato della stima.
            </p>
          ) : (
            <>
              <p>
                Grazie per aver utilizzato il nostro servizio di stima online.
              </p>
            </>
          )}
        </Row>

        <div
          id="submit"
          onClick={() => {
            setShowForm(true);
            setShowResult(false);
            setFormInfo({
              living_area: 100, // Max 1000, min 0
              date: new Date().toISOString(),
              category: null,
              address: {
                // description: "",
                nation: "IT",
                street: "",
                house_number: "",
                zip: "",
                town: "",
              },
              construction: {
                form: "SEMI_DETACHED_HOUSE",
                name: "Casa semi-indipendente",
              },
              construction_year: 2022, //Min 1800 - Max currentYear + 1
              plot_area: 100, // Min 0 max 1500
              rooms: 2,
              floor: 0,
              floor_number: 2,
              elevator: false,
              garages: false,
              outdoor_parking_space: false,
              equipment: {
                description: { name: "Buono", form: "MIDDLE_HIGH" },
              },
            });
          }}
        >
          Indietro
        </div>
      </Container>
    </>
  );
};

type PrintProps = {
  data: ResponseData;
  setReadyToPrint: React.Dispatch<React.SetStateAction<boolean>>;
  formInfo: FormData;
};

export const PrintHtml: React.FC<PrintProps> = ({
  data,
  setReadyToPrint,
  formInfo,
}) => {
  const position = [data.calc.coordinates.lat, data.calc.coordinates.lng];

  return (
    <PrintDiv id="to-print">
      <img src="header.png" id="header" alt="" />
      <img src="/logohomepage-grey.png" alt="logo" id="casa-logo-print" />
      <p id="slogan-print">IL PRIMO FRANCHISING IMMOBILIARE PER TROVARE CASA</p>
      <div className="container-print">
        <h5>
          Data&ensp;valutazione:&ensp;{new Date(data.date).toLocaleString()}
        </h5>
      </div>
      <div id="container-data">
        <span className="font-size">
          <p>
            Indirizzo:{" "}
            <b>
              {data.calc.address.street}, {data.calc.address.house_number}
            </b>
          </p>

          <p>
            Comune:{" "}
            <b>
              {data.calc.address.town} - {data.calc.address.zip}
            </b>
          </p>

          <p>
            Tipo: <b>{formInfo.category?.name}</b>
          </p>

          <p>
            Anno costruzione: <b>{formInfo.construction_year}</b>
          </p>

          <p>
            {formInfo.category?.form === "CONDOMINIUM" ? "Piano" : "Piani"}:{" "}
            <b>
              {formInfo.category?.form === "CONDOMINIUM"
                ? formInfo.floor
                : formInfo.floor_number}
            </b>
          </p>
          <p>
            Mq. commerciali: <b>{formInfo.living_area}</b>
          </p>
        </span>

        <span className="font-size">
          <p>
            Stanze: <b>{formInfo.rooms}</b>
          </p>
          <p>
            Ascensore: <b>{formInfo.elevator ? "Si" : "No"}</b>
          </p>
          <p>
            Garage: <b>{formInfo.garages ? "Si" : "No"}</b>
          </p>
          <p>
            Stato immobile: <b>{formInfo.equipment.description.name}</b>
          </p>
          <p>
            Valore minimo:{" "}
            <b>
              {data.meta.range.min.toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR",
              })}
            </b>
          </p>
          <p>
            Valore massimo:{" "}
            <b>
              {data.meta.range.max.toLocaleString("it-IT", {
                style: "currency",
                currency: "EUR",
              })}
            </b>
          </p>
        </span>

        <i className="break" />

        <div id="map">
          <MapContainer
            //@ts-ignore
            center={position}
            zoom={17}
            scrollWheelZoom={false}
            zoomControl={false}
            whenReady={() =>
              setTimeout(() => {
                setReadyToPrint(true);
              }, 1000)
            }
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              crossOrigin
            />

            <Marker
              //@ts-ignore
              position={position}
            />
          </MapContainer>
        </div>
      </div>

      <div id="incarico">
        <p className="incarico-text">
          La tecnologia che utilizziamo per effettuare le valutazioni
          restituisce un intervallo di valori minimi e massimi che non sempre
          rappresentano la reale quotazione di mercato dell'immobile.
        </p>

        <p className="incarico-text">
          Per effettuare una valutazione più accurata{" "}
          <b>
            è sempre consigliabile l'intervento/sopralluogo di un consulente
            specializzato.
          </b>
        </p>
      </div>

      <div id="copy-info">
        <span className="icons">
          <FmdGoodRoundedIcon />
          <p>Via Gioberti, 7 - Collegno (TO)</p>
        </span>
        <span className="icons">
          <LocalPhoneRoundedIcon />
          <p>011 583 6262</p>
        </span>
        <span className="icons">
          <EmailRoundedIcon />
          <p>info@casaè.it</p>
        </span>
        <div>
          <p className="ragione">Casa È Franchising Spa</p>
          <p className="ragione">P.Iva 12447540019</p>
        </div>
      </div>
    </PrintDiv>
  );
};

export default memo(ResultContainer);
