import { memo } from "react";
import { ClientData } from "../pages/Homepage";

type Props = {
  setClientData: React.Dispatch<React.SetStateAction<ClientData>>;
  clientData: ClientData;
  setAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  accepted: boolean;
};

const ClientDataComponent: React.FC<Props> = ({
  clientData,
  setClientData,
  setAccepted,
  accepted,
}) => {
  return (
    <>
      <p className="text-ready">La stima del tuo patrimonio è pronta!</p>
      <p className="name-text">
        La tecnologia che utilizziamo per effettuare le stime restituisce
        un intervallo di valori minimi e massimi che non sempre rappresentano la
        reale quotazione di mercato.
      </p>
      <p className="name-text">
        Per effettuare una valutazione più accurata <b>è sempre consigliabile
        l'intervento/sopralluogo di un consulente specializzato.</b>
      </p>
      <div id="name-form">
        <div id="checkbox-div">
          <input
            type="checkbox"
            id="checkbox"
            checked={accepted}
            onChange={(e) => setAccepted(e.target.checked)}
          />
          <label htmlFor="checkbox" id="checkbox-label">
            Richiedi una consulenza professionale gratuita
          </label>
        </div>

        <div id="checkbox-div">
          <input
            type="checkbox"
            id="checkbox-2"
            checked={!accepted}
            onChange={(e) => setAccepted(!e.target.checked)}
          />
          <label htmlFor="checkbox-2" id="checkbox-label">
            Visualizza pdf senza consulenza
          </label>
        </div>

        {accepted && (
          <>
            <div id="break" />

            <div className="input-container width">
              <label htmlFor="name">
                Nome e Cognome<span className="required-color">*</span>
              </label>
              <input
                type="text"
                required={accepted}
                name="name"
                id="name"
                onChange={(e) =>
                  setClientData({ ...clientData, name: e.currentTarget.value })
                }
              />
            </div>

            <div id="break" />

            <div className={`input-container`}>
              <label htmlFor="email">
                Email<span className="required-color">*</span>
              </label>
              <input
                type="email"
                required={accepted}
                name="email"
                onChange={(e) =>
                  setClientData({ ...clientData, email: e.currentTarget.value })
                }
              />
            </div>

            <div className="input-container">
              <label htmlFor="tel">Telefono</label>
              <input
                type="tel"
                name="tel"
                onChange={(e) =>
                  setClientData({
                    ...clientData,
                    telephone: e.currentTarget.value,
                  })
                }
              />
            </div>
          </>
        )}

        <div id="break" />
        <input type="submit" value={accepted ? "Invia" : "Visualizza stima"} id="submit" />
      </div>
    </>
  );
};

export default memo(ClientDataComponent);
