import { memo } from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;

  #spinner-custom {
    color: #fff;
    z-index: 3;
  }
`;

const SpinnerPage: React.FC = () => {
  return (
    <Container>
      <Spinner animation="border" role="status" id="spinner-custom">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  );
};

export default memo(SpinnerPage);
