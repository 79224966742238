import { createRef, memo, useCallback, useEffect, useState } from "react";
import { Form, Container, Row, Header } from "../theme/form";
import ReCAPTCHA from "react-google-recaptcha";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "../components/Spinner";
import useApi from "../hooks/useApi";
import ResultContainer, { PrintHtml } from "../components/ResultContainer";
import ClientDataComponent from "../components/ClientData";
import html2canvas from "html2canvas";
import { Hidden } from "../theme/result";
import Alert from "../components/Alert";
import jsPdf from "jspdf";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

export type FormData = {
  date: string;
  category: { form: string; name: string } | null;
  address: {
    description?: string;
    nation: string;
    street: string;
    house_number: string;
    zip: string;
    town: string;
  };
  construction: { form: string; name: string };
  construction_year: number;
  living_area: number;
  plot_area?: number;
  rooms: number;
  floor: number;
  floor_number: number;
  elevator: boolean;
  garages: boolean;
  outdoor_parking_space: boolean;
  equipment: {
    description: { name: string; form: string };
  };
};

export type ResponseData = {
  date: string;
  value: number;
  meta: {
    model: string;
    standarderror: number;
    score: number;
    range: { min: number; max: number };
  };
  calc: {
    address: {
      nation: string;
      street: string;
      house_number: string;
      zip: string;
      town: string;
    };
    coordinates: { lat: number; lng: number };
    equipment: string;
  };
};

export type ClientData = {
  name: string;
  telephone: string;
  email: string;
};

const { sendForm, uploadHouseInfo, uploadClientInfo } = useApi;

const categoryList: { name: string; form: string }[] = [
  { form: "CONDOMINIUM", name: "Appartamento" },
  {
    form: "SINGLE_FAMILY_HOUSE",
    name: "Soluzione indipendente/semi-indipendente",
  },
];
const constructionList: { name: string; form: string }[] = [
  {
    form: "SEMI_DETACHED_HOUSE",
    name: "Casa semi-indipendente",
  },
  {
    form: "DETACHED_HOUSE",
    name: "Casa indipendente",
  },
  {
    form: "END_TERRACE_HOUSE",
    name: "Casa a schiera (iniziale/finale)",
  },
  {
    form: "MID_TERRACE_HOUSE",
    name: "Casa a schiera",
  },
];
export const descriptionList: { name: string; form: string }[] = [
  { name: "Da ristrutturare", form: "SIMPLE" },
  { name: "Buono", form: "MIDDLE_HIGH" },
  { name: "Ristrutturato", form: "HIGH" },
  { name: "Nuovo", form: "VERY_HIGH" },
];

const Homepage: React.FC = () => {
  const recaptchaRef = createRef<React.LegacyRef<ReCAPTCHA> | undefined>();
  const recaptchaUploadHouseInfoRef = createRef<
    React.LegacyRef<ReCAPTCHA> | undefined
  >();

  const [showForm, setShowForm] = useState(true);
  const [clientData, setClientData] = useState<ClientData>({
    name: "",
    email: "",
    telephone: "",
  });

  const [readyToPrint, setReadyToPrint] = useState(false);
  const [houseId, setHouseId] = useState(0);
  const [accepted, setAccepted] = useState(true);
  const [sending, setSending] = useState(false);
  const [formInfo, setFormInfo] = useState<FormData>({
    living_area: 100, // Max 1000, min 0
    date: new Date().toISOString(),
    category: null,
    address: {
      // description: "",
      nation: "IT",
      street: "",
      house_number: "",
      zip: "",
      town: "",
    },
    construction: { ...constructionList[0] },
    construction_year: 2022, //Min 1800 - Max currentYear + 1
    rooms: 2,
    floor: 0,
    floor_number: 2,
    elevator: false,
    garages: false,
    outdoor_parking_space: false,
    equipment: { description: { name: "Buono", form: "MIDDLE_HIGH" } },
  });
  const [responseData, setResponseData] = useState<ResponseData | null>(null);
  const [showResult, setShowResult] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState<{
    text: string;
    variant:
      | "primary"
      | "secondary"
      | "success"
      | "danger"
      | "warning"
      | "info"
      | "light"
      | "dark";
  }>({ text: "", variant: "success" });

  const checkDataBeforeSubmit = useCallback(() => {
    if (
      !formInfo.address.street ||
      !formInfo.address.house_number ||
      !formInfo.address.town ||
      !formInfo.address.zip
    ) {
      return false;
    }
    if (!formInfo.category) {
      return false;
    }
    if (
      formInfo.category.form === "CONDOMINIUM" &&
      formInfo.living_area > 500
    ) {
      return false;
    }
    if (
      formInfo.category.form !== "CONDOMINIUM" &&
      formInfo.living_area > 1000
    ) {
      return false;
    }
    if (formInfo.rooms > 10) {
      return false;
    }
    return true;
  }, [formInfo]);

  const printResult = useCallback(
    async (client: ClientData) => {
      try {
        const canvas = await html2canvas(document.getElementById("to-print")!, {
          width: 826,
          height: 1169,
          useCORS: true,
        });

        if (accepted) {
          const blob: Blob = await new Promise((resolve, reject) => {
            return canvas.toBlob(
              (blob) => {
                if (blob) {
                  resolve(blob);
                }
                console.log("cannot create blob");
                reject("cannot create blob");
              },
              "image/jpeg",
              1
            );
          });

          const formData = new FormData();
          formData.append("upload", blob, "upload.jpeg");
          formData.append("email", client.email);

          await fetch(
            process.env.NODE_ENV === "production"
              ? "api/getPdf"
              : "http://localhost:8080/api/getPdf",
            {
              method: "POST",
              body: formData,
            }
          )
            .then(() => {
              setSending(false);
              setReadyToPrint(false);
              setShowResult(true);
              setAlertInfo({
                text: "La tua valutazione è stata inviata correttamente",
                variant: "success",
              });
              setShowAlert(true);
            })
            .catch((err) => {
              console.log("toPrint", err);
              alert(err);
              setAlertInfo({
                text: "Ops, la valutazione non è stata generata. Riprova più tardi!",
                variant: "danger",
              });
              setShowAlert(true);
              setSending(false);
            });
        } else {
          const doc = new jsPdf({
            format: "a4",
            orientation: "p",
            unit: "px",
            compress: true,
          });

          const width = doc.internal.pageSize.getWidth();
          const height = doc.internal.pageSize.getHeight();

          doc.addImage(
            canvas.toDataURL("image/jpeg"),
            "JPEG",
            0,
            0,
            width,
            height
          );
          doc.save(
            `Immobile - ${responseData?.calc.address.street}, ${responseData?.calc.address.house_number} - Casa È.pdf`
          );
          setSending(false);
          setReadyToPrint(false);
          setShowResult(true);
          return;
        }
      } catch (err) {
        console.log(err);
        setSending(false);
        setReadyToPrint(false);
        alert(err);
        setAlertInfo({
          text: "Ops, la valutazione non è stata generata. Riprova più tardi!",
          variant: "danger",
        });
        setShowAlert(true);
        return;
      }
    },
    [setSending, accepted, responseData]
  );

  const submitForm = useCallback(
    async (token: string | null) => {
      try {
        if (!token) {
          return;
        }
        //@ts-ignore
        recaptchaRef.current.reset();
        if (checkDataBeforeSubmit()) {
          setSending(true);

          // Qui inviamo i dati utenti e mandiamo email
          if (accepted) {
            await uploadClientInfo(formInfo, clientData, houseId, token).then(
              (data) => {
                if (!data) {
                  setSending(false);
                  setAlertInfo({
                    text: "Ops, la valutazione non è stata generata. Riprova più tardi!",
                    variant: "danger",
                  });
                  setShowAlert(true);
                  return;
                }
                setResponseData(data);
              }
            );
          } else {
            await sendForm(formInfo, houseId, token).then((data) => {
              if (!data) {
                setSending(false);
                setAlertInfo({
                  text: "Ops, la valutazione non è stata generata. Riprova più tardi!",
                  variant: "danger",
                });
                setShowAlert(true);
                return;
              }
              setResponseData(data);
            });
          }
        }
      } catch (error) {
        setSending(false);
      }
    },
    [
      formInfo,
      checkDataBeforeSubmit,
      recaptchaRef,
      accepted,
      clientData,
      houseId,
    ]
  );

  const handleSubmit = useCallback(
    async (token: string | null) => {
      if (!token) {
        return;
      }
      if (checkDataBeforeSubmit()) {
        setSending(true);
        const id = await uploadHouseInfo(formInfo, token);
        if (id) {
          setHouseId(id);
          setTimeout(() => {
            setShowForm(false);
            setSending(false);
          }, 1000);
        } else {
          setAlertInfo({
            text: "Ops, la valutazione non è stata generata. Riprova più tardi!",
            variant: "danger",
          });
          setShowAlert(true);
          setSending(false);
        }
      }
    },
    [checkDataBeforeSubmit, formInfo]
  );

  useEffect(() => {
    if (readyToPrint) {
      printResult(clientData);
    }
    //eslint-disable-next-line
  }, [readyToPrint, clientData]);

  return (
    <>
      <Container>
        {responseData && (
          <Hidden id="display">
            <PrintHtml
              data={responseData}
              setReadyToPrint={setReadyToPrint}
              formInfo={formInfo}
            />
          </Hidden>
        )}

        <div id="form-container">
          <Alert
            variant={alertInfo.variant}
            text={alertInfo.text}
            setShowAlert={setShowAlert}
            showAlert={showAlert}
          />

          <ReCAPTCHA
            sitekey="6LdVkgMiAAAAAJAn-6gDeZTLSOlZqdbz4L-Ebj1-"
            size="invisible"
            //@ts-ignore
            ref={recaptchaRef}
            onChange={submitForm}
          />

          <ReCAPTCHA
            sitekey="6LdVkgMiAAAAAJAn-6gDeZTLSOlZqdbz4L-Ebj1-"
            size="invisible"
            //@ts-ignore
            ref={recaptchaUploadHouseInfoRef}
            onChange={handleSubmit}
          />

          <Header />

          {!showResult ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                //@ts-ignore
                recaptchaRef.current.execute();
              }}
            >
              {showForm ? (
                <>
                  <div id="description-service">
                    <WorkspacePremiumIcon />
                    <h5>Ottieni una stima online del tuo immobile!</h5>
                    <p>
                      Valorizza velocemente il tuo patrimonio e raggiungi in
                      pochi secondi i tuoi obiettivi con il nostro calcolatore
                      online.
                    </p>
                    <p>Ricevi il tuo risultato ed affida a noi l'incarico.</p>

                    <p>
                      Scegli il tipo di immobile ed{" "}
                      <u>ottieni in pochi click la tua stima!!</u>
                    </p>
                  </div>
                  <Row className="center-row">
                    <Dropdown>
                      <Dropdown.Toggle id="category">
                        {formInfo.category
                          ? formInfo.category.name
                          : "Tipo di immobile"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {categoryList.map((category) => (
                          <Dropdown.Item
                            key={category.name}
                            onClick={() =>
                              setFormInfo({
                                ...formInfo,
                                category: {
                                  name: category.name,
                                  form: category.form,
                                },
                              })
                            }
                          >
                            {category.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Row>
                  {formInfo.category && (
                    <>
                      <Row>
                        <div className="box left">
                          <label htmlFor="street">
                            Indirizzo<span className="required-color">*</span>
                          </label>
                          <input
                            id="street"
                            type="text"
                            required
                            value={formInfo.address.street}
                            onChange={(e) =>
                              setFormInfo({
                                ...formInfo,
                                address: {
                                  ...formInfo.address,
                                  street: e.currentTarget.value,
                                },
                              })
                            }
                          />
                        </div>

                        <div className="box left">
                          <label htmlFor="number">
                            Civico<span className="required-color">*</span>
                          </label>
                          <input
                            id="number"
                            type="text"
                            required
                            value={formInfo.address.house_number}
                            onChange={(e) =>
                              setFormInfo({
                                ...formInfo,
                                address: {
                                  ...formInfo.address,
                                  house_number: e.currentTarget.value,
                                },
                              })
                            }
                          />
                        </div>

                        <div className="box left">
                          <label htmlFor="town">
                            Comune<span className="required-color">*</span>
                          </label>
                          <input
                            id="town"
                            type="text"
                            required
                            value={formInfo.address.town}
                            onChange={(e) =>
                              setFormInfo({
                                ...formInfo,
                                address: {
                                  ...formInfo.address,
                                  town: e.currentTarget.value,
                                },
                              })
                            }
                          />
                        </div>

                        <div className="box left">
                          <label htmlFor="zip">
                            Cap<span className="required-color">*</span>
                          </label>
                          <input
                            id="zip"
                            type="text"
                            required
                            value={formInfo.address.zip}
                            onChange={(e) =>
                              setFormInfo({
                                ...formInfo,
                                address: {
                                  ...formInfo.address,
                                  zip: e.currentTarget.value,
                                },
                              })
                            }
                          />
                        </div>
                      </Row>

                      <Row className="flex-end">
                        {formInfo.category.form === "SINGLE_FAMILY_HOUSE" && (
                          <div className="box no-grow left">
                            <label>Tipo</label>
                            <Dropdown>
                              <Dropdown.Toggle id="construction">
                                {formInfo.construction.name}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {constructionList.map((category) => (
                                  <Dropdown.Item
                                    key={category.name}
                                    onClick={() =>
                                      setFormInfo({
                                        ...formInfo,
                                        construction: {
                                          name: category.name,
                                          form: category.form,
                                        },
                                      })
                                    }
                                  >
                                    {category.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            <p className="input-subtitle">&ensp;</p>
                          </div>
                        )}
                        <div className="box no-grow left">
                          <label htmlFor="construction_year">
                            Anno di costruzione
                          </label>

                          <input
                            id="construction_year"
                            className="small-input"
                            type="text"
                            value={formInfo.construction_year}
                            maxLength={4}
                            onChange={(e) =>
                              setFormInfo({
                                ...formInfo,
                                construction_year: Number(e.currentTarget.value)
                                  ? Number(e.currentTarget.value)
                                  : 0,
                              })
                            }
                          />
                          <p className="input-subtitle">
                            Min: 1800 - Max: {new Date().getFullYear() + 1}
                          </p>
                        </div>

                        {formInfo.category.form === "CONDOMINIUM" && (
                          <div className="box no-grow left">
                            <label htmlFor="floor">Piano</label>

                            <input
                              id="floor"
                              className="small-input"
                              type="text"
                              value={formInfo.floor}
                              maxLength={3}
                              onChange={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  floor: Number(e.currentTarget.value)
                                    ? Number(e.currentTarget.value)
                                    : 0,
                                })
                              }
                            />
                            <p className="input-subtitle">Min: 0 - Max: 100</p>
                          </div>
                        )}

                        <div className="box no-grow left">
                          <label htmlFor="living_area">Mq. commerciali</label>

                          <input
                            id="living_area"
                            className="small-input"
                            type="text"
                            value={formInfo.living_area}
                            maxLength={4}
                            onChange={(e) =>
                              setFormInfo({
                                ...formInfo,
                                living_area: Number(e.currentTarget.value)
                                  ? Number(e.currentTarget.value)
                                  : 0,
                              })
                            }
                          />
                          <p className="input-subtitle">
                            Min: 0 - Max:{" "}
                            {formInfo.category.form === "CONDOMINIUM"
                              ? 500
                              : 1000}
                          </p>
                        </div>

                        <div className="box no-grow left">
                          <label htmlFor="rooms">Stanze</label>

                          <input
                            id="rooms"
                            className="small-input"
                            type="text"
                            value={formInfo.rooms}
                            maxLength={2}
                            onChange={(e) =>
                              setFormInfo({
                                ...formInfo,
                                rooms: Number(e.currentTarget.value)
                                  ? Number(e.currentTarget.value)
                                  : 0,
                              })
                            }
                          />
                          <p className="input-subtitle">Min: 0 - Max: 10</p>
                        </div>

                        {/*formInfo.category.form === "SINGLE_FAMILY_HOUSE" && (
                          <div className="box no-grow left">
                            <label htmlFor="plot_area">Superficie totale</label>

                            <input
                              id="plot_area"
                              className="small-input"
                              type="text"
                              value={formInfo.plot_area}
                              maxLength={4}
                              onChange={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  plot_area: Number(e.currentTarget.value)
                                    ? Number(e.currentTarget.value)
                                    : 0,
                                })
                              }
                            />
                            <p className="input-subtitle">Min: 0 - Max: 5000</p>
                          </div>
                            )*/}

                        {formInfo.category.form === "SINGLE_FAMILY_HOUSE" && (
                          <div className="box no-grow left">
                            <label htmlFor="floor_number">Piani</label>

                            <input
                              id="floor_number"
                              className="small-input"
                              type="text"
                              value={formInfo.floor_number}
                              maxLength={2}
                              onChange={(e) =>
                                setFormInfo({
                                  ...formInfo,
                                  floor_number: Number(e.currentTarget.value)
                                    ? Number(e.currentTarget.value)
                                    : 0,
                                })
                              }
                            />
                            <p className="input-subtitle">Min: 0 - Max: 10</p>
                          </div>
                        )}

                        {formInfo.category.form === "CONDOMINIUM" && (
                          <div className="box no-grow left">
                            <label htmlFor="rooms">Ascensore</label>

                            <Dropdown>
                              <Dropdown.Toggle className="custom-dropdown">
                                {formInfo.elevator ? "Si" : "No"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    setFormInfo({
                                      ...formInfo,
                                      elevator: true,
                                    })
                                  }
                                >
                                  Si
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    setFormInfo({
                                      ...formInfo,
                                      elevator: false,
                                    })
                                  }
                                >
                                  No
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <p className="input-subtitle">&ensp;</p>
                          </div>
                        )}

                        <div className="box no-grow left">
                          <label htmlFor="rooms">Garage</label>

                          <Dropdown>
                            <Dropdown.Toggle className="custom-dropdown">
                              {formInfo.garages ? "Si" : "No"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  setFormInfo({
                                    ...formInfo,
                                    garages: true,
                                  })
                                }
                              >
                                Si
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  setFormInfo({
                                    ...formInfo,
                                    garages: false,
                                  })
                                }
                              >
                                No
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <p className="input-subtitle">&ensp;</p>
                        </div>

                        <div className="box no-grow left">
                          <label htmlFor="rooms">Parcheggio</label>

                          <Dropdown>
                            <Dropdown.Toggle className="custom-dropdown">
                              {formInfo.outdoor_parking_space ? "Si" : "No"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  setFormInfo({
                                    ...formInfo,
                                    outdoor_parking_space: true,
                                  })
                                }
                              >
                                Si
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  setFormInfo({
                                    ...formInfo,
                                    outdoor_parking_space: false,
                                  })
                                }
                              >
                                No
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <p className="input-subtitle">Spazio esterno</p>
                        </div>

                        <div className="box no-grow left">
                          <label htmlFor="rooms">Stato immobile</label>

                          <Dropdown>
                            <Dropdown.Toggle className="custom-dropdown">
                              {formInfo.equipment.description.name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {descriptionList.map((item) => (
                                <Dropdown.Item
                                  key={item.form}
                                  onClick={() =>
                                    setFormInfo({
                                      ...formInfo,
                                      equipment: { description: { ...item } },
                                    })
                                  }
                                >
                                  {item.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <p className="input-subtitle">&ensp;</p>
                        </div>
                      </Row>

                      <div
                        id="submit"
                        onClick={() =>
                          //@ts-ignore
                          recaptchaUploadHouseInfoRef.current.execute()
                        }
                      >
                        Avanti
                      </div>

                      <p id="terms-form" className="copyright">
                        Proseguendo accetti i{" "}
                        <a
                          href="https://xn--casa-8oa.it/policy/informativa-clienti/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Termini e Condizioni d'uso del Servizio
                        </a>
                      </p>
                      <p className="copyright" id="terms-form">
                        Questo sito è protetto da reCAPTCHA e dalla Google{" "}
                        <a href="https://policies.google.com/privacy">
                          Privacy Policy
                        </a>{" "}
                        e{" "}
                        <a href="https://policies.google.com/terms">
                          Termini di Servizio
                        </a>
                      </p>
                    </>
                  )}
                </>
              ) : (
                <ClientDataComponent
                  clientData={clientData}
                  setClientData={setClientData}
                  setAccepted={setAccepted}
                  accepted={accepted}
                />
              )}
              {sending && <Spinner />}
            </Form>
          ) : (
            <ResultContainer
              setShowResult={setShowResult}
              clientData={clientData}
              setShowForm={setShowForm}
              setFormInfo={setFormInfo}
              accepted={accepted}
            />
          )}
        </div>

        <span id="terms">
          <p className="copyright">
            Developed by{" "}
            <a
              href="https://www.tera.software/"
              target="_blank"
              rel="noreferrer"
            >
              Tera Software
            </a>
          </p>
        </span>
      </Container>
    </>
  );
};

export default memo(Homepage);
